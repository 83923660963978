// @mui icons
import FacebookIcon from "@mui/icons-material/Facebook";
import TwitterIcon from "@mui/icons-material/Twitter";
// import GitHubIcon from "@mui/icons-material/GitHub";
import YouTubeIcon from "@mui/icons-material/YouTube";

// Material Kit 2 React components
import MKTypography from "components/MKTypography";

// Images
import logoCT from "assets/images/logos/manzia-logo-200.png";

const date = new Date().getFullYear();

export default {
  brand: {
    name: "Manzia AI",
    image: logoCT,
    route: "/",
  },
  socials: [
    {
      icon: <FacebookIcon />,
      link: "https://www.facebook.com/manzia/",
    },
    {
      icon: <TwitterIcon />,
      link: "https://twitter.com/manzia",
    },
    {
      icon: <YouTubeIcon />,
      link: "https://www.youtube.com/channel/UCVyTG4sCw-rOvB9oHkzZD1w",
    },
  ],
  menus: [
    {
      name: "company",
      items: [
        { name: "about us", href: "https://www.manzia.com/presentation" },
        { name: "blog", href: "https://www.manzia.com/blog" },
      ],
    },
    {
      name: "help & support",
      items: [
        { name: "contact us", href: "https://www.manzia.com/contact-us" },
        { name: "knowledge center", href: "https://www.manzia.com/help" },
      ],
    },
    {
      name: "legal",
      items: [
        { name: "terms & conditions", href: "https://www.manzia.com/terms" },
        { name: "privacy policy", href: "https://www.manzia.com/privacy" },
        { name: "usage policy", href: "https://www.manzia.com/usage" }
      ],
    },
  ],
  copyright: (
    <MKTypography variant="button" fontWeight="regular">
      All rights reserved. Copyright &copy; {date}{" "}
      <MKTypography
        component="a"
        href="https://www.manzia.com"
        target="_blank"
        rel="noreferrer"
        variant="button"
        fontWeight="regular"
      >
        Manzia AI Inc
      </MKTypography>
      .
    </MKTypography>
  ),
};
