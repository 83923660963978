const template = `
<!doctype html>
<html>
  <head>
    <meta charset="utf-8">
    <meta name="viewport" content="width=device-width,initial-scale=1,maximum-scale=1,user-scalable=no">
    <title>Manzia AI</title>
    <link href="data:image/x-icon;base64,iVBORw0KGgoAAAANSUhEUgAAABAAAAAQEAYAAABPYyMiAAAABmJLR0T///////8JWPfcAAAACXBIWXMAAABIAAAASABGyWs+AAAAF0lEQVRIx2NgGAWjYBSMglEwCkbBSAcACBAAAeaR9cIAAAAASUVORK5CYII=" rel="icon" type="image/x-icon"/>
    <link href="https://{{WebAppDomain}}/{{WebAppDomainPath}}/lex-web-ui-loader.min.css" rel="stylesheet">
    <style>
      .toolbar.theme--dark {
            background-color: {{toolbar.themedark.backgroundColor}} !important;
        }

        .toolbar__title {
          font-family: {{toolbar.title.fontFamily}} !important;
          font-size: {{toolbar.title.fontSize}}em !important;
          color: {{toolbar.title.color}} !important;
        }

        .message-list-container {
          background-color: {{messagelistcontainer.backgroundColor}} !important
        }

        .message-bot .message-bubble {
            background-color: {{messagebubble.messagebot.backgroundColor}} !important;
        }

        .message-human .message-bubble {
            background-color: {{messagebubble.messagehuman.backgroundColor}} !important;
        }

        .message-bubble p {
            margin-bottom: 8px;
        }

        .message-bubble p:last-child {
            margin-bottom: 0px;
        }

        .message-bubble .message-text {
            padding-left:  0;
            padding-right: 0;
            line-height: 1.6;
            font-size: {{messagebubble.messagetext.fontSize}}rem;
        }

        .message-bubble {
            border-radius: 10px !important;
            padding: 2px 18px !important;
        }

        .message-text {
          color: {{messagebubble.messagetext.color}};
          width: 100%;
        }

        .headline {
            font-size: 1.2rem !important;
            line-height: 1.4 !important;
        }

        .card__title {
            padding: 10px 16px !important;
        }

        .card__text {
            padding: 8px 16px 16px !important;
            line-height: 1.4;
        }

        .card__title.card__title--primary {
            background-color: {{cardtitle.cardtitleprimary.backgroundColor}} !important;
        }


        .input-group--text-field input,
        .input-group--text-field textarea,
        .input-group--text-field label {
            font-size: 14px !important;
        }

        .card__actions .btn {
          margin: 4px 4px !important;
          font-size: 1em !important;
          min-width: 44px !important;
          background-color: {{cardactions.btn.backgroundColor}} !important;
        }

        button.min-button {
            border-radius: 60px;
        }

        .message-button {
            display: none;
        }
    </style>
  </head>
  <body>
    <script src="https://{{WebAppDomain}}/{{WebAppDomainPath}}/lex-web-ui-loader.min.js"></script>
    <script>
    function sendMessageToComponent(message) {
      try {
        var myEvent = new CustomEvent('lexwebuicomponent', {
          detail: {
            event: 'postText',
            message: message
          }
        });
        document.dispatchEvent(myEvent);
        
      } catch { }
    }
    
    var Loader = ChatBotUiLoader.FullPageLoader;
    var loaderOpts = {
      baseUrl: 'https://{{WebAppDomain}}/{{WebAppDomainPath}}/',
      
      shouldLoadConfigFromJsonFile: true,
      configUrl: 'https://{{WebAppDomain}}/{{WebAppDomainPath}}/lex-web-ui-loader-config.json',
      shouldLoadMinDeps: true
    };
    var loader = new Loader(loaderOpts);
   
    var chatbotUiConfig = {
      lex: {
        sessionAttributes: {
          webDomain: "{{webDomain}}",
          productPage: "{{productPage}}",
          productName: "{{productName}}",
          guestId: "{{guestId}}"
        }
      }
    };
    loader
      .load(chatbotUiConfig)
      .then(function () { 
          setTimeout(() => {
            sendMessageToComponent("engage");
          }, 1000);
       })
      .catch(function (error) { console.error(error); });
    
    </script>
  </body>
</html>
`; 

export default template;