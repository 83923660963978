/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Manzia
import WorkCard from "pages/HomePage/components/WorkCard";

function Customize() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <MKTypography variant="h3" color="white">
              Tailor ManziaBot to fit your brand.
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              From conversation flows to visual elements, customize every aspect to align with your company&apos;s unique voice and style.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "", 
                  color: "info", 
                  label: "Brand voice", 
                  description: "Customize ManziaBot to match your brand voice and tone." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "", 
                  color: "info", 
                  label: "Brand image", 
                  description: "Customize ManziaBot look and feel to match your brand image." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "", 
                  color: "info", 
                  label: "Trust and safety", 
                  description: "ManziaBot incorporates comprehensive guardrails against negative and disrespectful conversations." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} lg={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "", 
                  color: "info", 
                  label: "Data privacy and security", 
                  description: "Manzia AI complies with GDPR and adheres to the highest data protection standards." 
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Customize;
