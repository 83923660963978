/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function Price({ price, color }) {
    return (
        <MKBox pt={3} pb={2} px={2} textAlign="center">
          <MKBox my={1}>
            <MKTypography variant="h1" color={color}>
              <MKTypography
                display="inline"
                component="small"
                variant="h5"
                color="inherit"
                verticalAlign="top"
              >
                {price.currency}
              </MKTypography>
              {price.value}
              <MKTypography display="inline" component="small" variant="h5" color="inherit">
                /{price.type}
              </MKTypography>
            </MKTypography>
          </MKBox>
        </MKBox>
    );
}

Price.defaultProps = {
    color: "info"
};

Price.propTypes = {
    price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
    color: PropTypes.string
};

export default Price;