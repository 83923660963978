/**
=========================================================
* Manzia AI Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import AppBar from "@mui/material/AppBar";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";

// Sections
import Team from "pages/About/sections/Team";
import OurStory from "pages/About/sections/OurStory";
import AIBeliefs from "pages/About/sections/AIBeliefs";

// Material Kit 2 React components
import MKBox from "components/MKBox";
// import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

function AboutUs() {
  const [activeTab, setActiveTab] = useState(0);

  const handleTabType = (_, newValue) => setActiveTab(newValue);

  return (
    <MKBox component="section" py={{ xs: 0, lg: 6 }}>
        <Container>
            <Grid container item>
                <MKBox
                    width="100%"
                    bgColor="white"
                    borderRadius="xl"
                    shadow="xl"
                    minHeight="100vh"
                    mb={6}
                    sx={{ overflow: "hidden" }}
                >
                    <Grid container item justifyContent="center" xs={12} lg={4} mx="auto" mt={4}>
                        <AppBar position="static">
                        <Tabs 
                            value={activeTab} 
                            onChange={handleTabType}
                            textColor="info"
                            indicatorColor="info"
                        >
                            <Tab label="Our Story" />
                            <Tab label="About AI" />
                            <Tab label="The Team" />
                        </Tabs>
                        </AppBar>
                    </Grid>
                    {activeTab === 0 && <OurStory />}
                    {activeTab === 1 && <AIBeliefs />}
                    {activeTab === 2 && <Team />}
                </MKBox>
            </Grid>
        </Container>
    </MKBox>
    
  );
}

export default AboutUs;