/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// react-countup component
// import CountUp from "react-countup";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Manzia
import Price from "pages/HomePage/components/Price";

function PriceCard({ price, title, description }) {
  return (
    <MKBox lineHeight={1}>
      <Price price={price} />
      {title && (
        <MKTypography variant="h5" mt={2} mb={1} fontWeight="bold">
          {title}
        </MKTypography>
      )}
      {description && (
        <MKTypography variant="button" color="dark" fontWeight="regular">
          {description}
        </MKTypography>
      )}
    </MKBox>
  );
}

// Setting default props for the DefaultCounterCard
PriceCard.defaultProps = {
  description: "",
  title: "",
};

// Typechecking props for the DefaultCounterCard
PriceCard.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  price: PropTypes.shape({
        currency: PropTypes.string,
        value: PropTypes.string,
        type: PropTypes.string
    }).isRequired,
};

export default PriceCard;
