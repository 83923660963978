/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback, useRef, useState } from "react";

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

import { useParams } from 'react-router-dom';

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Hooks
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import InputField from "pages/Demo/components/InputField";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import DelayedLoadView from "layouts/common/DelayedLoadView";
import LoadMessages from "pages/Demo/components/DemoForm/loadingMessages";
import { BASE64_REGEX, decodeString } from "helpers";

// schemas for form and form fields
import validations from "pages/Demo/schema/validations";
import form from "pages/Demo/schema/form";
import initialValues from "pages/Demo/schema/initialValues";
import { ManziaDemo, ManziaAPI } from "appConstants";

// Constants
const productTimeout = 90000;

/* eslint-disable  no-empty */
function getInitialValues(prod) {
    let initValues = initialValues.demo;
    if (!prod || prod.length < 2) return initValues;
    if (BASE64_REGEX.test(prod)) {
        try {
            initValues = JSON.parse(decodeString(prod));
        } catch {}
    }
    return initValues;
}
/* eslint-enable  no-empty */

function DemoForm({ onComplete }) {
    const { prod } = useParams();
    const { demo: { formId, productName, productPage } } = form;
    const [product, setProduct] = useState(null);
    const productPageRef = useRef(getInitialValues(prod)[productPage.name]);

    // Product socket
    const productSocketRef = useRef();
    const timeoutRef = useRef();
    const [delayedLoader, setDelayedLoader] = useState(false);

    // Recaptcha
    const recaptchaRef = useRef();
    const [productError, setProductError] = useState(null);

    const { loading, error, value, fetch } = useLazyAxios();

    // Fetch complete
    useEffect(() => {
        if (!value) return;
        if (value.data) {
            setProduct(value.data);
        } else if (value.errors) {
            setProductError(value.errors.message || "Encountered error generating product info.");
        }
    }, [value]);

    useEffect(() => {
        if (!product) return;
        console.log("Product: ", product);
        onComplete(product);
    }, [product]);

    // Fetch timeout
    useEffect(() => {
        if (!delayedLoader) return;
        timeoutRef.current = setTimeout(() => {
            setProductError("Timeout while generating product info. Please retry later.");
            setDelayedLoader(false);
        }, productTimeout + 30000);

        // Clear timeout
        return () => clearTimeout(timeoutRef.current);
    }, [delayedLoader]);
    
    // Create websocket connection
    const socketOnMessage = (e) => { 
        // console.log("Product page: ", productPageRef.current);
        // Check if its our message since backend broadcasts
        try {
            const { productName, productPage } = JSON.parse(e.data);
            if (productPageRef.current === productPage) {
                setProduct({ productName, productPage });
                setDelayedLoader(false); 
            }
        } catch (error) {
            setProductError("Response error while generating product info.");
            setDelayedLoader(false);
        }
    }

    const socketOnError = () => { 
        setProductError("Connection error while generating product info.");
        setDelayedLoader(false);
    };

    const socketOnOpen = () => setDelayedLoader(true);

    useEffect(() => {
        productSocketRef.current = new WebSocket(ManziaDemo.socket.wsurl);
        productSocketRef.current.onmessage = socketOnMessage;
        productSocketRef.current.onerror = socketOnError;
        productSocketRef.current.onopen = socketOnOpen;

        // Close
        return () => productSocketRef.current?.close();
    }, []);

    // Submit
    const handleSubmit = useCallback((values, actions) => {
        productPageRef.current = values[productPage.name];

        recaptchaRef.current.executeAsync()
            .then((recaptchaToken) => {
                fetch()({
                    method: ManziaAPI.demo.method, 
                    url: ManziaAPI.demo.url,
                    data: { ...values, recaptchaToken },
                    timeout: 60000,
                    maxRedirects: 0, 
                });
            }).catch(() => {
                setProductError("Validation failed. Please try again later.");

            }).finally(() => { 
                recaptchaRef.current.reset();
            });
        
        // Update state
        actions.setSubmitting(false);
        // actions.resetForm();
    
    }, []);

  return (
    <Formik
        initialValues={getInitialValues(prod)}
        validationSchema={validations.demo}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            const isDisabled = isSubmitting || !isValid;
            return (
            <Form id={formId} autoComplete="on">
                {(error || productError)  && <ErrorView error={{ message: `${error || productError}` }} />}
                {(delayedLoader && value && !value.data && !value.errors) && 
                    <DelayedLoadView 
                        messages={LoadMessages} 
                        cycleTime={productTimeout}
                        color="success" 
                    />}
                <MKBox my={2}>
                    <InputField 
                        name={productName.name} 
                        type={productName.type}
                        label={productName.label}
                        fullWidth 
                    />
                </MKBox>
                <MKBox mb={2}>
                    <InputField 
                        name={productPage.name} 
                        type={productPage.type}
                        label={productPage.label}
                        helperText="Enter the product URL that contains details such as features, social proof, use cases etc."
                        fullWidth 
                    />
                </MKBox>
                
                <MKBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                >
                
                {loading && <LoadView loadingMessage="" />}
                <MKButton 
                    variant="gradient" 
                    color="info" 
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={isDisabled}
                >
                    Start demo
                </MKButton>
                </MKBox>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={ManziaDemo.recaptchaKey}
                    badge="bottomright"
                />
            </Form>
          )}}
    </Formik>
  );

}

DemoForm.propTypes = {
    onComplete: PropTypes.func.isRequired
};

export default DemoForm;


