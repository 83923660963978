/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState, useCallback } from "react";


// Layout components
import IllustrationLayout from "layouts/IllustrationLayout";

// Manzia
import DemoForm from "pages/Demo/components/DemoForm";
import DemoBot from "pages/Demo/components/DemoBot";
import { ManziaDemo } from "appConstants";

// Image
import bgImage from "assets/images/YManzia-AI-1200x1200.png";


function DemoManager() {
  const [ demoProduct, setDemoProduct] = useState(null);
  // const demoProductRef = useRef();

  const onComplete = useCallback((product) => {
    if (!demoProduct) setDemoProduct(product);
  }, []);

  // const onComplete = (product) => demoProductRef.current = product;

  return demoProduct ? 
    (<DemoBot product={demoProduct} />) : 
    (<IllustrationLayout
      title={ManziaDemo.form.title}
      description={ManziaDemo.form.description}
      illustration={bgImage}
    >
       <DemoForm onComplete={onComplete} />
    </IllustrationLayout>)
  ;
}

export default DemoManager;
