/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

const getInvalidMsg = (fieldName) => `${fieldName} can only contain up to 50 alphanumeric, comma, dash, underscore, hyphen, whitespace characters.`;

const appForms = {
  demo: {
    formId: "demo-form",
    productName: {
        name: "productName",
        label: "Product Name",
        type: "text",
        errorMsg: "Product name is required.",
        invalidMsg: "Invalid product name",
        illegalChars: "Only alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
    },
    productPage: {
        name: "productPage",
        label: "Product Page URL",
        type: "text",
        errorMsg: "Product page is required.",
        invalidMsg: "Product page URL is invalid.",
        placeholder: "https://www.example.com/product-page"
    },
  },
  contactus: {
    formId: "contact-form",
    fullName: {
      name: "fullName",
      label: "Full name",
      type: "text",
      placeholder: "e.g John Doe",
      errorMsg: "Full name is required.",
      invalidMsg: getInvalidMsg("Full name")
    },
    contactEmail: {
      name: "contactEmail",
      label: "Email",
      type: "text",
      placeholder: "e.g abc@example.com",
      errorMsg: "Email is required.",
      invalidMsg: "Enter a valid email address"
    },
    contactMessage: {
        name: "contactMessage",
        label: "Your message",
        type: "text",
        errorMsg: "Message is required.",
        invalidMsg: "At least 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
        placeholder: "How do I...",
    },
  },
  feedback: {
    formId: "feedback-form",
    fullName: {
      name: "fullName",
      label: "Full name",
      type: "text",
      placeholder: "e.g John Doe",
      errorMsg: "Full name is required.",
      invalidMsg: getInvalidMsg("Full name")
    },
    feedbackEmail: {
      name: "feedbackEmail",
      label: "Email",
      type: "text",
      placeholder: "e.g abc@example.com",
      errorMsg: "Email is required.",
      invalidMsg: "Enter a valid email address"
    },
    feedbackMessage: {
        name: "feedbackMessage",
        label: "Did we meet your expectations?",
        type: "text",
        errorMsg: "Message is required.",
        invalidMsg: "At least 5 words with alphanumeric, comma, period, hyphen, single quote, double quote, exclamation mark, question mark, left or right parentheses, or whitespace characters allowed.",
        placeholder: "",
    },
  },
  emailchat: {
    formId: "emailchat-form",
    fullName: {
      name: "fullName",
      label: "Your name",
      type: "text",
      placeholder: "e.g Jane Doe",
      errorMsg: "Your name is required.",
      invalidMsg: getInvalidMsg("Your name")
    },
    chatEmails: {
      name: "chatEmails",
      label: "Send to email(s)",
      type: "text",
      placeholder: "e.g abc@company.com, xyz@company.com",
      errorMsg: "Send to email(s) required.",
      invalidMsg: "Enter valid email address(es)"
    },
  },
  notready: {
    formId: "notready-form",
    visitorEmail: {
        name: "visitorEmail",
        label: "Email",
        type: "text",
        placeholder: "e.g abc@example.com",
        errorMsg: "Email is required.",
        invalidMsg: "Enter a valid email address"
    }
  },
  botstyles: {
    formId: "chatbot-styles",
    formField: {
      toolbar: {
        name: "toolbar",
        label: "Toolbar",
        type: "text",
        themedark: {
          name: "themedark",
          label: "Toolbar Dark Theme",
          type: "text",
          backgroundColor: {
            name: "backgroundColor",
            label: "Toolbar Background Color",
            type: "text",
            errorMsg: "Toolbar background color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          }
        },
        title: {
          name: "title",
          label: "Toolbar Title",
          type: "text",
          fontFamily: {
            name: "fontFamily",
            label: "Toolbar Fonts",
            type: "text",
            errorMsg: "Toolbar font family is required.",
            invalidMsg: "Invalid font family"
          },
          fontSize: {
            name: "fontSize",
            label: "Toolbar Font Size (em)",
            type: "number",
            adorn: "em",
            errorMsg: "Toolbar font size is required.",
            invalidMsg: "Invalid font size, must be number greater than zero"
          },
          color: {
            name: "color",
            label: "Toolbar Title Color",
            type: "text",
            errorMsg: "Toolbar title color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          } 
        }
      },
      messagelistcontainer: {
        name: "messagelistcontainer",
        label: "Message Container",
        type: "text",
        backgroundColor: {
          name: "backgroundColor",
          label: "Message Container Background Color",
          type: "text",
          errorMsg: "Container background color is required.",
          invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
        }
      },
      messagebubble: {
        name: "messagebubble",
        label: "Message Bubble",
        type: "text",
        messagebot: {
          name: "messagebot",
          label: "Bot Message Bubble",
          type: "text",
          backgroundColor: {
            name: "backgroundColor",
            label: "Bot Message Background Color",
            type: "text",
            errorMsg: "Message bubble background color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          }
        },
        messagehuman: {
          name: "messagehuman",
          label: "Human Message Bubble",
          type: "text",
          backgroundColor: {
            name: "backgroundColor",
            label: "Human Message Background Color",
            type: "text",
            errorMsg: "Message bubble background color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          }
        },
        messagetext: {
          name: "messagetext",
          label: "Message Bubble Text",
          type: "text",
          color: {
            name: "color",
            label: "Message Text Color",
            type: "text",
            errorMsg: "Message text color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          },
          fontSize: {
            name: "fontSize",
            label: "Message Text Font Size",
            type: "number",
            adorn: "rem",
            errorMsg: "Message text font size is required.",
            invalidMsg: "Invalid font size, must be number greater than zero"
          }  
        }
      },
      cardtitle: {
        name: "cardtitle",
        label: "Card Title",
        type: "text",
        cardtitleprimary: {
          name: "cardtitleprimary",
          label: "Card Title",
          type: "text",
          backgroundColor: {
            name: "backgroundColor",
            label: "Card Title Background Color",
            type: "text",
            errorMsg: "Card title background color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          }
        }
      },
      cardactions: {
        name: "cardactions",
        label: "Card Actions",
        type: "text",
        btn: {
          name: "btn",
          label: "Card Action Btn",
          type: "text",
          backgroundColor: {
            name: "backgroundColor",
            label: "Card Action Btn Background Color",
            type: "text",
            errorMsg: "Background color is required.",
            invalidMsg: "Expects hex code e.g #FFF or #FFFFFF"
          }
        }
      }
    },
  }
}

export default appForms;
