/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Manzia
import WorkCard from "pages/HomePage/components/WorkCard";

function Working() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 4 }}>
            <MKTypography variant="h3" color="white">
              How it works
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              ManziaBot is highly customizable. As more visitors engage, the quality of conversations improves significantly.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "1", 
                  color: "info", 
                  label: "Create customer profile (ICP)", 
                  description: "Use your website content to generate a customer profile (ICP)." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "2", 
                  color: "info", 
                  label: "Detect visitor exit", 
                  description: "Launch ManziaBot and engage visitor just before exit." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "3", 
                  color: "info", 
                  label: "Classify visitor objections", 
                  description: "Use Machine Learning (ML) to categorize visitor objections." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} md={6}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "4", 
                  color: "info", 
                  label: "Qualify visitor", 
                  description: "Use Generative AI to generate qualifying responses/questions." 
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Working;
