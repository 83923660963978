/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import DefaultCounterCard from "examples/Cards/CounterCards/DefaultCounterCard";

// Manzia
import PriceCard from "pages/HomePage/components/PriceCard";

function Pricing() {
  return (
    <MKBox component="section" pt={3} pb={8} my={4}>
      <Container>
        <MKBox>
          <MKTypography variant="h3" color="info">
              Compare our pricing
          </MKTypography>
        </MKBox>
        <Grid container justifyContent="space-between" sx={{ textAlign: "center" }}>
          <Grid item xs={12} md={3}>
            <PriceCard
              price={{ currency: "$", value: "0.15", type: "conversation"}}
              title="Manzia AI"
              description="Conversation is a bot session with one or more visitor utterances."
            />
          </Grid>
          <Grid item xs={12} md={3}>
          <PriceCard
              price={{ currency: "$", value: "1-2", type: "per click"}}
              title="Google"
              description="Estimated average cost per click (CPC) on Google Search Network as of 2023."
            />
          </Grid>
          <Grid item xs={12} md={3}>
          <PriceCard
              price={{ currency: "$", value: "0.26", type: "per click"}}
              title="Facebook"
              description="Estimated average cost per click for Facebook ads in 2023."
            />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Pricing;
