/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import form from "pages/Demo/schema/form";

const {
  demo: { productName, productPage },
  contactus: { fullName, contactEmail, contactMessage },
  feedback: { fullName: feedbackFullName, feedbackEmail, feedbackMessage },
  notready: { visitorEmail },
  emailchat: { fullName: chatFullName, chatEmails },
  botstyles: {
    formField: { toolbar, messagelistcontainer, messagebubble,
      cardtitle, cardactions },
  }
} = form;

const initialValues = {
  demo: {
    [productName.name]: "",
    [productPage.name]: ""
  },
  contactus: {
    [fullName.name]: "",
    [contactEmail.name]: "",
    [contactMessage.name]: "",
  },
  feedback: {
    [feedbackFullName.name]: "",
    [feedbackEmail.name]: "",
    [feedbackMessage.name]: "",
  },
  emailchat: {
    [chatFullName.name]: "",
    [chatEmails.name]: "",
  },
  notready: {
    [visitorEmail.name]: "",
  },
  botstyles: {
    [toolbar.name]: {
      [toolbar.themedark.name]: {
        [toolbar.themedark.backgroundColor.name]: "#1a73e8"
      },
      [toolbar.title.name]: {
        [toolbar.title.fontFamily.name]: "Sans-serif",
        [toolbar.title.fontSize.name]: 0.875,
        [toolbar.title.color.name]: "#ffffff"
      }
    },
    [messagelistcontainer.name]: {
      [messagelistcontainer.backgroundColor.name]: "#4a4a4a"
    },
    [messagebubble.name]: {
      [messagebubble.messagebot.name]: {
        [messagebubble.messagebot.backgroundColor.name]: "#6c757d"
      },
      [messagebubble.messagehuman.name]: {
        [messagebubble.messagehuman.backgroundColor.name]: "#4CAF50"
      },
      [messagebubble.messagetext.name]: {
        [messagebubble.messagetext.color.name]: "#ffffff",
        [messagebubble.messagetext.fontSize.name]: 1
      }
    },
    [cardtitle.name]: {
      [cardtitle.cardtitleprimary.name]: {
        [cardtitle.cardtitleprimary.backgroundColor.name]: "#eeedeb"
      }
    },
    [cardactions.name]: {
      [cardactions.btn.name]: {
        [cardactions.btn.backgroundColor.name]: "#afcffa"
      }
    },
  }
}

export default initialValues;
