import { useCallback, useState } from "react"

// Network
import axios from "axios";

export default function useLazyAxios(dependencies = []) {
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState();
    const [value, setValue] = useState();

    const callbackMemoized = useCallback(() => (request) => {
        setLoading(true);
        setError(undefined);
        setValue(undefined);
        axios({
            ...request,
            headers: {'Authorization': 'token' },
        }).then((response) => setValue(response.data))
            .catch((err) => { 
                if (err.response && err.response.data) {
                    const { type = "Error", cause, message } = err.response.data;
                    setError(`${type}: ${cause || message || "Unknown failure."}`);
                } else {
                    setError("Error: Network fetch operation failed.");
                    // console.log(err.toJSON());
                }
            })
            .finally(() => setLoading(false));
    }, dependencies);

    return { loading, error, value, fetch: callbackMemoized };
}
