/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import * as Yup from "yup";
import form from "pages/Demo/schema/form";
import { FULL_NAME_REGEX, illegalChars, fourAndIllegalChars } from "helpers";

const {
    demo: { productName, productPage },
    contactus: { fullName, contactEmail, contactMessage },
    feedback: { fullName: feedbackFullName, feedbackEmail, feedbackMessage },
    notready: { visitorEmail },
    emailchat: { fullName: chatFullName, chatEmails },
  } = form;

const validations = {
  demo: Yup.object().shape({
    [productName.name]: Yup.string().matches(illegalChars, productName.illegalChars).required(productName.errorMsg),
    [productPage.name]: Yup.string().url(productPage.invalidMsg).required(productPage.errorMsg),
  }),
  contactus: Yup.object().shape({
    [fullName.name]: Yup.string().matches(FULL_NAME_REGEX, fullName.invalidMsg).required(fullName.errorMsg),
    [contactEmail.name]: Yup.string().required(contactEmail.errorMsg).email(contactEmail.invalidMsg),
    [contactMessage.name]: Yup.string().matches(fourAndIllegalChars, contactMessage.invalidMsg).required(contactMessage.errorMsg),
  }),
  feedback: Yup.object().shape({
    [feedbackFullName.name]: Yup.string().matches(FULL_NAME_REGEX, feedbackFullName.invalidMsg),
    [feedbackEmail.name]: Yup.string().required(feedbackEmail.errorMsg).email(feedbackEmail.invalidMsg),
    [feedbackMessage.name]: Yup.string().matches(fourAndIllegalChars, feedbackMessage.invalidMsg).required(feedbackMessage.errorMsg),
  }),
  emailchat: Yup.object().shape({
    [chatFullName.name]: Yup.string().matches(FULL_NAME_REGEX, chatFullName.invalidMsg).required(chatFullName.errorMsg),
    [chatEmails.name]: Yup.array()
    .transform(function (value, originalValue) {
      if (this.isType(value) && value !== null) {
        return value;
      }
      return originalValue ? originalValue.split(/[\s,]+/) : [];
    })
    .of(Yup.string().email(({ value }) => `${value} is not a valid email`))
    .required(chatEmails.errorMsg)
  }),
  notready: Yup.object().shape({
    [visitorEmail.name]: Yup.string().required(visitorEmail.errorMsg).email(visitorEmail.invalidMsg),
  })
}

export default validations;
