/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function WorkCard({ step }) {
  return (
    <Card sx={{ mt: 3 }}>
      <Grid container>
        <Grid item xs={12} sx={{ my: "auto" }}>
          <MKBox pt={{ xs: 1, lg: 2.5 }} pb={2.5} pr={4} pl={{ xs: 4, lg: 1 }} lineHeight={1}>
            <MKTypography variant="h5" color={step.color} mb={1}>
              {step.name.length > 0 ? `Step ${step.name} - ${step.label}` : `${step.label}`}
            </MKTypography>
            <MKTypography variant="body2" color="dark" fontWeight="regular">
              {step.description}
            </MKTypography>
          </MKBox>
        </Grid>
      </Grid>
    </Card>
  );
}

// Typechecking props for the HorizontalTeamCard
WorkCard.propTypes = {
    step: PropTypes.shape({
        name: PropTypes.string.isRequired,
        color: PropTypes.oneOf([
            "primary",
            "secondary",
            "info",
            "success",
            "warning",
            "error",
            "dark",
            "light",
          ]),
          label: PropTypes.string.isRequired,
          description: PropTypes.string.isRequired,
    }).isRequired,
};

export default WorkCard;
