/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props.
import PropTypes from "prop-types";

// React router
import { Outlet, useLocation } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";

// Material Kit 2 React examples
import DefaultNavbar from "examples/Navbars/DefaultNavbar";
import DefaultFooter from "examples/Footers/DefaultFooter";

// Routes
import routes from "routes";
import footerRoutes from "footer.routes";

function NavBar({ pathname }) {
    return (
        <DefaultNavbar
            routes={routes}
            action={{
                type: "external",
                route: "https://dashboard.manzia.com",
                label: "Start Your Free Trial",
                color: "default",
            }}
            transparent
            light
            relative={pathname !== "/"}
        />
    );
}

function AppLayout() {
    const location = useLocation();

    return (
        <>
        {location.pathname === "/" ? <NavBar pathname={location.pathname} /> : 
        (<MKBox variant="gradient" bgColor="dark" shadow="sm" py={0.25}>
            <NavBar pathname={location.pathname} />
        </MKBox>)}
        <Outlet />
        <MKBox pt={6} px={1} mt={6}>
            <DefaultFooter content={footerRoutes} />
        </MKBox>
        </>
    );
}

NavBar.propTypes = {
    pathname: PropTypes.string.isRequired
}

export default AppLayout;
