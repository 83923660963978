/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback, useRef, useState } from "react";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// import { useParams } from 'react-router-dom';

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Hooks
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import InputField from "pages/Demo/components/InputField";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import NotificationView from "layouts/common/NotificationView";

// schemas for form and form fields
import validations from "pages/Demo/schema/validations";
import form from "pages/Demo/schema/form";
import initialValues from "pages/Demo/schema/initialValues";
import { ManziaDemo, ManziaAPI } from "appConstants";

function ContactForm() {
    const { contactus: { formId, fullName, contactEmail, 
        contactMessage } } = form;
    const [submitted, setSubmitted] = useState(false);

    // Recaptcha
    const recaptchaRef = useRef();
    const [contactError, setContactError] = useState(null);

    const { loading, error, value, fetch } = useLazyAxios();

    // Fetch complete
    useEffect(() => {
        if (!value) return;
        if (value.data) {
            const { code } = value.data;
            setSubmitted(code === "Success");
        } else if (value.errors) {
            setContactError(value.errors.message || "Encountered error during submission.");
        }
    }, [value]);

    // Submit
    const handleSubmit = useCallback((values, actions) => {

        recaptchaRef.current.executeAsync()
            .then((recaptchaToken) => {
                fetch()({
                    method: ManziaAPI.contactus.method, 
                    url: ManziaAPI.contactus.url,
                    data: { ...values, recaptchaToken },
                    timeout: 60000,
                    maxRedirects: 0, 
                });
            }).catch(() => {
                setContactError("Validation failed. Please try again later.");

            }).finally(() => { 
                recaptchaRef.current.reset();
            });
        
        // Update state
        actions.setSubmitting(false);
        // actions.resetForm();
    
    }, []);

  return (
    <Formik
        initialValues={initialValues.contactus}
        validationSchema={validations.contactus}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            const isDisabled = isSubmitting || !isValid;
            return (
            <Form id={formId} autoComplete="on">
                {(error || contactError)  && <ErrorView error={{ message: `${error || contactError}` }} />}
                {submitted && 
                    <NotificationView  
                        color="success" 
                        message="Submitted. A Manzia AI team member will be in touch shortly."
                    />
                }
                <MKBox my={2}>
                    <InputField 
                        name={fullName.name} 
                        type={fullName.type}
                        label={fullName.label}
                        fullWidth 
                    />
                </MKBox>
                <MKBox mb={2}>
                    <InputField 
                        name={contactEmail.name} 
                        type={contactEmail.type}
                        label={contactEmail.label}
                        fullWidth 
                    />
                </MKBox>
                <MKBox mb={2}>
                    <InputField 
                        name={contactMessage.name} 
                        type={contactMessage.type}
                        label={contactMessage.label}
                        multiline
                        rows={6}
                        fullWidth 
                    />
                </MKBox>
                <MKBox 
                    mt={4} 
                    mb={1}
                    display="flex"
                    alignItems="center"
                >
                
                {loading && <LoadView loadingMessage="" />}
                <MKButton 
                    variant="gradient" 
                    color="info" 
                    size="large"
                    type="submit"
                    fullWidth
                    disabled={isDisabled}
                >
                    Send Message
                </MKButton>
                </MKBox>
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={ManziaDemo.recaptchaKey}
                    badge="bottomright"
                />
            </Form>
          )}}
    </Formik>
  );

}

export default ContactForm;


