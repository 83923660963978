/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";
// import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
import DefaultInfoCard from "examples/Cards/InfoCards/DefaultInfoCard";


function Features() {
  return (
    <MKBox component="section" py={12}>
      <Container>
        <Grid container spacing={3} alignItems="center" justifyContent="flex-start">
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="web"
                title="Browse"
                description="ManziaBot can automatically crawl your product pages to extract product information. You only need to specify the URLs."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="cloud"
                title="Deploy"
                description="ManziaBot tag can easily be deployed to your website and managed via Google Tag Manager. You can also manually install the ManziaBot tag."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="notifications"
                title="Trigger"
                description="ManziaBot automatically launches when website visitors start to exit. Frequency of launches can be customized and defaults to once every 24 hours."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="speed"
                title="Customize"
                description="Engage in test conversations with ManziaBot to customize its responses. Mark test conversations to be used as examples when engaging website visitors."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="analytics"
                title="Analyze"
                description="View visitor conversations and metrics. Easily filter conversations for leads i.e conversations with visitor contact details."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={5}>
              <DefaultInfoCard
                icon="paid"
                title="Spend"
                description="Set monthly spending limits to manage usage-based billing costs. You can also set spending alerts to keep up to date."
              />
            </MKBox>
          </Grid>
          <Grid item xs={12}>
            <MKBox display="flex" justifyContent="center">
                <MKButton 
                  variant="gradient" 
                  color="info" 
                  sx={{ height: "100%" }}
                  size="large"
                  >
                  Start Your Free Trial
                </MKButton>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Features;
