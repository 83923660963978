
// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKAlert from "components/MKAlert";


function NotificationView({ color, message }) {
    return (
        <MKBox p={2}>
            <MKAlert color={color} dismissible>
                <MKTypography variant="button" color="white">
                    {message}
                </MKTypography>
            </MKAlert>
        </MKBox>
    );
}

// typechecking props for UserInfo
NotificationView.propTypes = {
    color: PropTypes.oneOf([
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]).isRequired,
    message: PropTypes.string.isRequired
};

export default NotificationView;