/**
=========================================================
* Manzia AI Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

function PageLayout({ background, children }) {
    return (
        <MKBox
            width="100vw"
            height="100%"
            minHeight="100vh"
            bgColor={background}
            sx={{ overflowX: "hidden" }}
        >
            {children}
        </MKBox>
    );
}

function IllustrationLayout({ header, title, description, illustration, children }) {
  
  return (
    <PageLayout background="white">
      <Grid
        container
        sx={{
          backgroundColor: ({ palette: { white } }) => white.main,
        }}
      >
        <Grid item xs={12} lg={6}>
          <MKBox
            display={{ xs: "none", lg: "flex" }}
            width="calc(100% - 2rem)"
            height="calc(100vh - 2rem)"
            borderRadius="lg"
            ml={2}
            mt={2}
            sx={{ backgroundImage: `url(${illustration})`, backgroundSize: 'contain', backgroundRepeat: 'no-repeat' }}
          />
        </Grid>
        <Grid item xs={11} sm={8} md={6} lg={6} xl={4} sx={{ mx: "auto" }}>
          <MKBox display="flex" flexDirection="column" justifyContent="center" height="100vh">
            <MKBox py={1} px={3} textAlign="center">
              {!header ? (
                <>
                  <MKBox mb={1} textAlign="center">
                    <MKTypography variant="h4" fontWeight="bold">
                      {title}
                    </MKTypography>
                  </MKBox>
                  <MKTypography variant="body2" color="dark" fontWeight="regular">
                    {description}
                  </MKTypography>
                </>
              ) : (
                header
              )}
            </MKBox>
            <MKBox px={3}>{children}</MKBox>
          </MKBox>
        </Grid>
      </Grid>
    </PageLayout>
  );
}

PageLayout.defaultProps = {
    background: "white"
}

PageLayout.propTypes = {
    background: PropTypes.oneOf([
        "default",
        "white",
        "primary",
        "secondary",
        "info",
        "success",
        "warning",
        "error",
        "light",
        "dark",
    ]),
    children: PropTypes.node.isRequired
}

// Setting default values for the props of IllustrationLayout
IllustrationLayout.defaultProps = {
  header: "",
  title: "",
  description: "",
  illustration: "",
};

// Typechecking props for the IllustrationLayout
IllustrationLayout.propTypes = {
  header: PropTypes.node,
  title: PropTypes.string,
  description: PropTypes.string,
  children: PropTypes.node.isRequired,
  illustration: PropTypes.string,
};

export default IllustrationLayout;
