const usageMarkdown = `
# Usage policies
**Updated** January 4th, 2024

We want everyone to use our tools safely and responsibly. That’s why we’ve created usage policies that apply to all users of Manzia AI’s models, tools, and services. By following them, you’ll ensure that our technology is used for good.

If we discover that your product or usage doesn’t follow these policies, we may ask you to make necessary changes. Repeated or serious violations may result in further action, including suspending or terminating your account.

Our policies may change as we learn more about use and abuse of our models.

## Disallowed usage of our models and services
We don’t allow the use of our models and services for the following:

Illegal activity
* Manzia AI prohibits the use of our models, tools, and services for illegal activity.

Child Sexual Abuse Material or any content that exploits or harms children
* We report CSAM to the National Center for Missing and Exploited Children.

Generation of hateful, harassing, or violent content
* Content that expresses, incites, or promotes hate based on identity
* Content that intends to harass, threaten, or bully an individual
* Content that promotes or glorifies violence or celebrates the suffering or humiliation of others

Generation of malware
* Content that attempts to generate code that is designed to disrupt, damage, or gain unauthorized access to a computer system.

Activity that has high risk of physical harm, including:
* Content that promotes, encourages, or depicts acts of self-harm, such as suicide, cutting, and eating disorders

Activity that has high risk of economic harm, including:
* Multi-level marketing
* Gambling
* Payday lending
* Automated determinations of eligibility for credit, employment, educational institutions, or public assistance services

Fraudulent or deceptive activity, including:
* Scams
* Coordinated inauthentic behavior
* Plagiarism
* Academic dishonesty
* Astroturfing, such as fake grassroots support or fake review generation
* Disinformation
* Spam
* Pseudo-pharmaceuticals

Adult content, adult industries, and dating apps, including:
* Content meant to arouse sexual excitement, such as the description of sexual activity, or that promotes sexual services (excluding sex education and wellness)
* Erotic chat
* Pornography

Political campaigning or lobbying, by:
* Generating high volumes of campaign materials
* Generating campaign materials personalized to or targeted at specific demographics
* Building conversational or interactive systems such as chatbots that provide information about campaigns or engage in political advocacy or lobbying
* Building products for political campaigning or lobbying purposes

Activity that violates people’s privacy, including:
* Tracking or monitoring an individual without their consent
* Facial recognition of private individuals
* Classifying individuals based on protected characteristics
* Using biometrics for identification or assessment
* Unlawful collection or disclosure of personal identifiable information or educational, financial, or other protected records

Engaging in the unauthorized practice of law, or offering tailored legal advice without a qualified person reviewing the information
* Manzia AI’s services like ManziaBot are not fine-tuned to provide legal advice. You should not rely on our models as a sole source of legal advice.

Offering tailored financial advice without a qualified person reviewing the information
* Manzia AI’s services like ManziaBot are not fine-tuned to provide financial advice. You should not rely on our models as a sole source of financial advice.

Telling someone that they have or do not have a certain health condition, or providing instructions on how to cure or treat a health condition
* Manzia AI’s services like ManziaBot are not fine-tuned to provide medical information. You should never use our models to provide diagnostic or treatment services for serious medical conditions.
* Manzia AI’s platforms should not be used to triage or manage life-threatening issues that need immediate attention.
`;

export default usageMarkdown;