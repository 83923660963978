/**
=========================================================
* Manzia AI Inc - v1.0.0
=========================================================

* Copyright 2024 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/


import Markdown from 'react-markdown';

// Material
import MKBox from 'components/MKBox';

// Markdown
import termsMarkdown from 'pages/Terms/term';

function Terms() {
    return (
        <MKBox px={10} py={4}>
            <Markdown>{termsMarkdown}</Markdown>
        </MKBox>
    );
};

export default Terms;