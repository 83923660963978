/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Grid from "@mui/material/Grid";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";

// Material Dashboard 2 PRO React examples
import TimelineList from "pages/Conversation/components/TimelineList";
import TimelineItem from "pages/Conversation/components/TimelineItem";

// Data
import timelineData from "pages/Conversation/data/conversation";

function Conversation() {
  const renderTimelineItems = timelineData.map(
    ({ color, icon, title, description, lastItem }, idx) => (
      <TimelineItem
        key={`${title}-${idx}`}
        color={color}
        icon={icon}
        title={title}
        description={description}
        lastItem={lastItem}
      />
    )
  );

  return (
      <MKBox sx={{ maxHeight: '300px', overflow: 'auto' }}>
        <Grid container spacing={3}>
          <Grid item xs={12}>
            <TimelineList title="ManziaBot in action...">
              {renderTimelineItems}
            </TimelineList>
          </Grid>
        </Grid>
      </MKBox>
  );
}

export default Conversation;
