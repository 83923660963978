/**
=========================================================
* Manzia AI Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


function OurStory() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      m={2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="h3" color="white">
              Our Story
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              It&apos;s the festive season at the end of 2022. A small startup our founder is involved with launches a major advertising campaign, attracting tens of thousands, mostly from Facebook, to their website.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              The conversion rate was abysmal. Even worse, though the advertising platforms could give us demographics and even psychographics of these visitors, they could not tell us anything about why these visitors came but did not convert. What had held them back?
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              Trying to answer this question, why they came but did not convert…gave birth to Manzia AI. Our approach is to empathetically engage and talk to these visitors at scale and in real-time.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              Generative AI now makes achieving this mission a real possibility. Manzia AI is on a mission to transform how businesses engage and talk to potential customers at scale.
            </MKTypography>
          </Grid>
        </Grid>
        
      </Container>
    </MKBox>
  );
}

export default OurStory;
