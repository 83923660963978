/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React router
import { Outlet } from "react-router-dom";

// Material Kit 2 React components
// import MKBox from "components/MKBox";

function HelpLayout() {
   
    return (
        <Outlet />
    );
}

export default HelpLayout;
