/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useEffect, useCallback, useRef, useState } from "react";

// prop-types is a library for typechecking of props
// import PropTypes from "prop-types";

// import { useParams } from 'react-router-dom';

// @mui material components
import Grid from "@mui/material/Grid";

// formik components
import { Formik, Form } from "formik";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKButton from "components/MKButton";

// Recaptcha
import ReCAPTCHA from "react-google-recaptcha";

// Hooks
import useLazyAxios from "customhooks/useLazyAxios";

// Manzia
import InputField from "pages/Demo/components/InputField";
import ErrorView from "layouts/common/ErrorView";
import LoadView from "layouts/common/LoadView";
import NotificationView from "layouts/common/NotificationView";

// schemas for form and form fields
import validations from "pages/Demo/schema/validations";
import form from "pages/Demo/schema/form";
import initialValues from "pages/Demo/schema/initialValues";
import { ManziaDemo, ManziaAPI } from "appConstants";

function SuccessForm() {
    const { notready: { formId, visitorEmail } } = form;
    const [submitted, setSubmitted] = useState(false);

    // Recaptcha
    const recaptchaRef = useRef();
    const [contactError, setContactError] = useState(null);

    const { loading, error, value, fetch } = useLazyAxios();

    // Fetch complete
    useEffect(() => {
        if (!value) return;
        if (value.data) {
            const { code } = value.data;
            setSubmitted(code === "Success");
        } else if (value.errors) {
            setContactError(value.errors.message || "Encountered error during submission.");
        }
    }, [value]);
   

    // Submit
    const handleSubmit = useCallback((values, actions) => {

        recaptchaRef.current.executeAsync()
            .then((recaptchaToken) => {
                fetch()({
                    method: ManziaAPI.notready.method, 
                    url: ManziaAPI.notready.url,
                    data: { ...values, recaptchaToken },
                    timeout: 60000,
                    maxRedirects: 0, 
                });
            }).catch(() => {
                setContactError("Validation failed. Please try again later.");

            }).finally(() => { 
                recaptchaRef.current.reset();
            });
        
        // Update state
        actions.setSubmitting(false);
        // actions.resetForm();
    
    }, []);

  return (
    <Formik
        initialValues={initialValues.notready}
        validationSchema={validations.notready}
        onSubmit={handleSubmit}
        validateOnMount
    >
        {({ isSubmitting, isValid }) => {
            const isDisabled = isSubmitting || !isValid;
            return (
            <Form id={formId} autoComplete="on">
                {(error || contactError)  && <ErrorView error={{ message: `${error || contactError}` }} />}
                {submitted && 
                    <NotificationView  
                        color="success" 
                        message="Submitted. A Manzia AI team member will be in touch shortly."
                    />
                }
                <Grid container spacing={1}>
                    <Grid item xs={8}>
                        <InputField 
                            name={visitorEmail.name} 
                            type={visitorEmail.type}
                            label={visitorEmail.label}
                            fullWidth 
                        />
                    </Grid>
                    <Grid item xs={4}>
                        <MKBox 
                            display="flex"
                            alignItems="center"
                        >
                            {loading && <LoadView loadingMessage="" />}
                            <MKButton 
                                variant="gradient" 
                                color="info"
                                type="submit"
                                sx={{ height: "100%", ml: 1 }}
                                fullWidth
                                disabled={isDisabled}
                            >
                                Send them
                            </MKButton>
                        </MKBox>
                    </Grid>
                </Grid>
                
                <ReCAPTCHA
                    ref={recaptchaRef}
                    size="invisible"
                    sitekey={ManziaDemo.recaptchaKey}
                    badge="bottomright"
                />
            </Form>
          )}}
    </Formik>
  );

}

export default SuccessForm;


