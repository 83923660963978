/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

export const ManziaAPI = {
    demo: { method: 'post', url: "https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/product-dev" },
    contactus: { method: 'post', url: "https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/contactus" },
    notready: { method: 'post', url: "https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/notready" },
    feedback: { method: 'post', url: "https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/feedback" },
    emailchat: { method: 'post', url: "https://hlagp1sbw7.execute-api.us-east-1.amazonaws.com/v1/emailchat" },
};

export const ManziaDemo = {
    form: { 
        title: "Tell us about your B2B business",
        description: "Please note. It takes a few minutes to process your product page content."
    },
    demoDomain: "webdev.manzia.com",
    recaptchaKey: "6Lc0FVsoAAAAANG79uWNLmVXyPzl5ly2DQn-SSfu",
    socket: { wsurl: "wss://06cqa298zc.execute-api.us-east-1.amazonaws.com/production/" },
    shortDomain: "link.manzia.com",
    sessionKey: "manzia.demo.guestId"
};

export const botStyleTemplates = {
    bucket: "manzia-lex-webui-apps",
    webuiTemplateKey: "templates/webui-template.html",
    cssStyleTemplateKey: "templates/chatbot-style-template.css",
    currentCssStyles: "custom-chatbot-style.css",
    configKey: "lex-web-ui-loader-config.json",
};

export const WebAppDomain = "web.manzia.com";

export default ManziaAPI;