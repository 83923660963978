/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-type is a library for typechecking of props
import PropTypes from "prop-types";

// formik components
import { ErrorMessage, Field } from "formik";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKInput from "components/MKInput";

function InputField({ label, name, variant, ...rest }) {
  return (
    <MKBox mb={1.5}>
      <Field {...rest} name={name} as={MKInput} variant={variant} label={label} fullWidth />
      <MKBox mt={0.75}>
        <MKTypography component="div" variant="caption" color="error" fontWeight="regular">
          <ErrorMessage name={name} />
        </MKTypography>
      </MKBox>
    </MKBox>
  );
}

// Specifies the default values for props:
InputField.defaultProps = {
  variant: 'outlined'
};

// typechecking props for FormField
InputField.propTypes = {
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  variant: PropTypes.string
};

export default InputField;
