export function base64ToBytes(base64) {
    const binString = atob(base64);
    return Uint8Array.from(binString, (m) => m.codePointAt(0));
}

export function bytesToBase64(bytes) {
    const binString = String.fromCodePoint(...bytes);
    return btoa(binString);
}

export function encodeString(str) {
    return bytesToBase64(new TextEncoder().encode(str));
}

export function decodeString(encodedStr) {
    return new TextDecoder().decode(base64ToBytes(encodedStr));
}

/* eslint-disable */
export const BASIC_FIELD_REGEX = /^[a-zA-Z0-9-,. _]{1,50}$/;

export const FULL_NAME_REGEX = /^[a-zA-Z0-9\s-]{1,100}$/;

export const DOMAIN_NAME = /^([\da-z\.-]+)\.([a-z\.]{2,6})$/;

export const ALPHA_SPACE_REGEX = /^[A-Za-z]+(?:\s[A-Za-z]+)*$/;

export const illegalChars = /^[a-zA-Z0-9,\.\-'"\?!()\s]+$/;

export const oneToFiveAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){0,4}[\w,'.\-\?!]+\s*$/;

export const fourAndIllegalChars = /^\s*(?:[\w,'.\-\?!]+\s+){4,}[\w,'.\-\?!]+\s*$/;

export const UUID_REGEX = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;

export const BASE64_REGEX = /^(?:[A-Za-z0-9+\/]{4})*(?:[A-Za-z0-9+\/]{2}==|[A-Za-z0-9+\/]{3}=)?$/;

/* eslint-enable */


export default base64ToBytes;
