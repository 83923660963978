/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

* Coded by www.manzia.com

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useRef } from "react";

import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
// import Divider from "@mui/material/Divider";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Moustache
import Mustache from "mustache";

// UUID
// import { uuid } from 'uuidv4';
import { v4 as uuid } from 'uuid';

// Hooks
// import useSessionStorage from "customhooks/useSessionStorage";
import useLocalStorage from "customhooks/useLocalStorage";

// Manzia
import initialValues from "pages/Demo/schema/initialValues";
import { WebAppDomain, ManziaDemo } from "appConstants";
import ShareLink from "pages/Demo/components/Share";
import ShareFeedback from "pages/Demo/components/ShareFeedback";
import SendEmail from "pages/Demo/components/SendEmail";

// Templates
import webuiTemplate from "pages/Demo/templates/webuiTemplate";


/* eslint-disable func-names */
Mustache.escape = function(text) {return text;};
/* eslint-enable func-names */


function BotAppearanceView({ product, guestId }) {
  const WebAppDomainPath = ManziaDemo.demoDomain.replaceAll("-", "_").replaceAll(".", "-");
  const botHtml = Mustache.render(webuiTemplate, 
      { ...initialValues.botstyles, 
        WebAppDomain, 
        WebAppDomainPath, 
        webDomain: ManziaDemo.demoDomain,
        ...product,
        guestId 
      });
  // console.log("Render HTML: ", botHtml);

  return (
    <MKBox display='flex' justifyContent='center'>
      <iframe
        title="Bot Styles"
        id="manzia-bot-iframe" 
        width="80%" 
        height="540" 
        srcDoc={botHtml} 
        frameBorder="0" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" 
        allowFullScreen 
      />
    </MKBox>
  );
}

function DemoBot({ product }) {
  const guestIdRef = useRef(uuid());
  const [guestId] = useLocalStorage(ManziaDemo.sessionKey, guestIdRef.current);

  return (
    <Card id="bot-appearance" sx={{ overflow: "visible", margin: 2 }}>
      <MKBox p={2}>
        <MKTypography variant="h4" color="dark" fontWeight="bold" sx={{ textAlign: 'center' }}>
            ManziaBot starts the conversations...so you can take over and develop the relationships.
        </MKTypography>
        <Grid container justifyContent="center" mt={2}>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox display="flex" justifyContent="space-between" alignItems="center">
              <ShareFeedback product={product} guestId={guestId} />
              <SendEmail product={product} guestId={guestId} />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={4} md={4}>
            <MKBox display="flex" justifyContent="flex-end">
              <ShareLink product={product} guestId={guestId} />
            </MKBox>
          </Grid>
          <Grid item sm={12}>
              <BotAppearanceView product={product} guestId={guestId} />
          </Grid>
        </Grid>
      </MKBox>
    </Card>
  );
}

BotAppearanceView.propTypes = {
  product: PropTypes.shape({
    productPage: PropTypes.string.isRequired,
    productName: PropTypes.string
  }),
  guestId: PropTypes.string.isRequired
};

DemoBot.propTypes = {
  product: PropTypes.shape({
    productPage: PropTypes.string.isRequired,
    productName: PropTypes.string
  })
};

export default DemoBot;
