/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Icon from "@mui/material/Icon";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Timeline context
// import { useTimeline } from "examples/Timeline/context";

// Custom styles for the TimelineItem
import timelineItem from "pages/Conversation/components/TimelineItem/styles";

function TimelineItem({ color, icon, title, description, lastItem }) {
  const isDark = true;

  return (
    <MKBox position="relative" mb={2} sx={(theme) => timelineItem(theme, { lastItem, isDark })}>
      <MKBox
        display="flex"
        justifyContent="center"
        alignItems="center"
        bgColor={color}
        color="white"
        width="2rem"
        height="2rem"
        borderRadius="50%"
        position="absolute"
        top="8%"
        left="2px"
        zIndex={2}
        sx={{ fontSize: ({ typography: { size } }) => size.sm }}
      >
        <Icon fontSize="inherit">{icon}</Icon>
      </MKBox>
      <MKBox ml={5.75} pt={description ? 0.7 : 0.5} lineHeight={0} maxWidth="30rem">
        <MKTypography variant="button" fontWeight="medium" color={isDark ? "white" : "dark"}>
          {title}
        </MKTypography>
        <MKBox mt={0.5} mb={1}>
          {description ? (
            <MKTypography variant="button" color={isDark ? "white" : "dark"}>
              {description}
            </MKTypography>
          ) : null}
        </MKBox>
      </MKBox>
    </MKBox>
  );
}

// Setting default values for the props of TimelineItem
TimelineItem.defaultProps = {
  color: "info",
  lastItem: false,
  description: "",
};

// Typechecking props for the TimelineItem
TimelineItem.propTypes = {
  color: PropTypes.oneOf([
    "primary",
    "secondary",
    "info",
    "success",
    "warning",
    "error",
    "dark",
    "light",
  ]),
  icon: PropTypes.node.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  lastItem: PropTypes.bool,
};

export default TimelineItem;
