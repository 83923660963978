/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Icon from "@mui/material/Icon";
// import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKAvatar from "components/MKAvatar";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Images
// import ManziaLogo from "assets/images/YManzia-AI.png";
import Conversation from "pages/Conversation";

function TargetCustomer() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="white"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container sx={{ mb: 2 }}>
          <Grid item xs={12} sm={6} sx={{ mb: 2 }}>
            <MKTypography variant="h3" color="info" sx={{ mb: 2 }}>
              For B2B software companies
            </MKTypography>
              <MKBox display="flex" alignItems="center" p={2}>
                
                <MKBox
                  variant="gradient"
                  color="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon fontSize="large">check</Icon>
                </MKBox>
                <MKTypography variant="body1" color="dark" fontWeight="regular" pl={2}>
                  Don&apos;t miss out on driving more qualified leads to your demos, trials, calendar, and CRM.
                </MKTypography>
              </MKBox>
              <MKBox display="flex" alignItems="center" p={2}>
                
                <MKBox
                  variant="gradient"
                  color="info"
                  display="flex"
                  alignItems="center"
                  justifyContent="center"
                >
                  <Icon fontSize="large">check</Icon>
                </MKBox>
                <MKTypography variant="body1" color="dark" fontWeight="regular" pl={2}>
                  Don&apos;t miss out on gaining real insights from visitor conversations that can focus your product roadmap.
                </MKTypography>
              </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} sx={{ ml: "auto", py: 3 }}>
            {/* <MKBox position="relative" display="flex" justifyContent="center">
              <MKBox component="img" src={ManziaLogo} alt="Manzia Logo" width="50%"/>
            </MKBox> */}
            <Conversation />
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default TargetCustomer;
