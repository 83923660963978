import { useEffect, useState } from "react"

function getSavedValue(key, initialValue) {
    let savedValue = localStorage.getItem(key);
    if (savedValue) {
        try {
            savedValue = JSON.parse(savedValue);
        } catch (error) { savedValue = null; }
    }
    if (savedValue) return savedValue;
    if (initialValue instanceof Function) return initialValue();
    return initialValue;
}

export default function useLocalStorage(key, initialValue) {
    
    const [value, setValue] = useState(() => getSavedValue(key, initialValue));

    useEffect(() => {
        localStorage.setItem(key, JSON.stringify(value));
    }, [value]);

    return [ value, setValue ];
}
