/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React router
// import { Link } from "react-router-dom";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Images
// import ManziaLogo from "assets/images/YManzia-AI.png";

function Blog() {
    
    return (
        <MKBox 
            display="flex" 
            justifyContent="center"
            alignItems="center" 
            minHeight={{ xs: "100vh", sm: "80vh" }}
            width="100%"
            bgColor="white"
        >
            {/* <Link to={"/"}>
                <MKBox component="img" src={ManziaLogo} alt="Manzia AI" mb={2} />
            </Link> */}
            <MKBox mt={2}>
                <MKTypography variant="h2">Our blog is coming soon...</MKTypography>
            </MKBox>
        </MKBox>
    );
}

export default Blog;
