/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// prop-types is a library for typechecking of props
import PropTypes from "prop-types";

// @mui material components
import Card from "@mui/material/Card";

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Dashboard 2 PRO React components
// import { useMaterialUIController } from "context";

// Timeline context
// import { TimelineProvider } from "examples/Timeline/context";

function TimelineList({ title, children }) {
  // const [controller] = useMaterialUIController();
  // const { darkMode } = controller;

  return (
      <Card>
        <MKBox
          bgColor={"dark"}
          variant="gradient"
          borderRadius="xl"
          // sx={{ background: ({ palette: { background } }) => darkMode && background.card }}
        >
          <MKBox pt={2} px={2}>
            <MKTypography variant="h6" fontWeight="medium" color="white">
              {title}
            </MKTypography>
          </MKBox>
          <MKBox p={2}>{children}</MKBox>
        </MKBox>
      </Card>
  );
}

// Typechecking props for the TimelineList
TimelineList.propTypes = {
  title: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
};

export default TimelineList;
