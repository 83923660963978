/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
// import Stack from "@mui/material/Stack";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
// import MKBadge from "components/MKBadge";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";


function Differentiate() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 3, mt: 2 }}>
            <MKTypography variant="h3" color="white">
              ManziaBot qualifies...you take over and develop the relationship.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={2} sx={{ mb: 4, p: 2 }}>
          <Grid item xs={12}>
          <MKTypography variant="body1" color="white" opacity={0.8}>
              ManziaBot is designed to ask qualifying questions, get contact info and handle common objections such as these and many more:
            </MKTypography>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                I&apos;m just looking
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
          <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                You are too expensive
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                Let me think about it
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                I need to do more research
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                Doesn&apos;t really work for me
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                Not sure I need this
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                I&apos;m not interested
              </MKTypography>
            </MKBox>
          </Grid>
          <Grid item xs={6} sm={4} md={3}>
            <MKBox sx={{ wordWrap: 'break-word' }}>
              <MKTypography 
                variant="body1" 
                color="white" 
                fontWeight="bold"
                sx={{ fontStyle: 'italic'}}
              >
                Key features are missing
              </MKTypography>
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Differentiate;
