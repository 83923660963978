import { useState } from "react";

import PropTypes from "prop-types";

// Dialog
import Dialog from '@mui/material/Dialog';
// import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
// import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
// import Icon from "@mui/material/Icon";
import IconButton from '@mui/material/IconButton';
import CloseIcon from '@mui/icons-material/Close';

// Material Dashboard
import MKButton from 'components/MKButton';

// Manzia
import FeedbackForm from "pages/Demo/components/FeedbackForm";

function ShareFeedback({ product, guestId }) {
    
    const [open, setOpen] = useState(false);
    
    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

  return (
    <>
    <MKButton 
        variant="outlined" 
        color="dark"
        aria-label="share your experience" 
        onClick={handleClickOpen}
    >
        Help us improve
    </MKButton>
      <Dialog open={open} onClose={handleClose} fullWidth >
        <DialogTitle>Share your experience of using ManziaBot.</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent>
            <FeedbackForm product={product} guestId={guestId} />
        </DialogContent>
      </Dialog>
    </>
  );
}

ShareFeedback.propTypes = {
    product: PropTypes.shape({
      productPage: PropTypes.string.isRequired,
      productName: PropTypes.string
    }),
    guestId: PropTypes.string.isRequired
  };

export default ShareFeedback;