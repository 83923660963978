const timelineData = [
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "I understand chatbots can sometimes be frustrating and you might not want to talk to one. As a Trusted Advisor, I respect your time and won't waste it. It sounds like you're facing challenges in lead qualification and customer engagement. Could you tell me more about your specific concerns?",
    },
    {
      color: "success",
      icon: "person",
      title: "Visitor",
      description:
        "I'm just looking around",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "It sounds like you're in the early stages of exploring solutions. What caught your eye about ManziaBot today?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "Using a chatbot to qualify leads sounds interesting",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "That's insightful. How do you currently handle lead qualification, and what challenges are you facing with your current method?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "We have a contact form on our website to qualify leads",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "It seems like you're looking for ways to enhance your lead qualification process. What specific issues have you encountered using the contact form approach?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "The conversion rates are really low",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "It's challenging when conversion rates don't meet expectations. What steps have you considered to improve these rates?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "We've optimized the website and added a demo",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "Optimizing the website and adding a demo are great steps. How has visitor engagement changed since implementing these updates?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "Not much really",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "It sounds like despite your efforts, engagement hasn't improved as hoped. Could you share your email so someone from our team can provide more details on how ManziaBot can increase engagement and conversion rates on your site?",
    },
    {
        color: "success",
        icon: "person",
        title: "Visitor",
        description:
          "roymanzi@example.com",
    },
    {
        color: "info",
        icon: "sentiment_satisfied_alt",
        title: "ManziaBot",
        description:
          "Thank you for sharing your email, Roy. Our team will be in touch with more information. Meanwhile, feel free to check out the ManziaBot demo here: ManziaBot demo for a closer look at how it works.",
        lastItem: true,
    },
  ];
  
  export default timelineData;