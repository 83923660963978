/*
=========================================================
* Material Kit 2 React - v2.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/material-kit-react
* Copyright 2023 Creative Tim (https://www.creative-tim.com)

Coded by www.creative-tim.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";


function AIBeliefs() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      m={2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="h3" color="white">
              Thoughts on AI
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              We believe that every human being has the innate desire to be heard, listened to and understood. We believe that fulfilling this desire is the foundation upon which human relationships blossom and flourish. This includes interactions between humans and AI agents such as conversational chatbots.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
              There is an ongoing philosophical debate about the good, bad and ugly of AI. But ultimately, AI is a tool, albeit a really powerful tool. It is the responsibility of each of us to use this powerful tool for the betterment of society and the world at large. Governments will regulate AI but each individual that uses and advances AI will have to make their own ethical and moral choices irrespective of the law.
            </MKTypography>
          </Grid>
          <Grid item xs={12} sx={{ mb: 2 }}>
            <MKTypography variant="body1" color="white" fontWeight="regular" opacity={0.8}>
            At Manzia AI, we solemnly commit that our use of AI will always be in service of the betterment of humanity and our planet even when not required by law. Our conviction is that interactions between humans and AI agents such as conversational chatbots can be fulfilling and meaningful if listening, empathy and understanding take center stage.
            </MKTypography>
          </Grid>
        </Grid>
        
      </Container>
    </MKBox>
  );
}

export default AIBeliefs;
