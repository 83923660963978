/*
=========================================================
* Manzia Inc - v1.0.0
=========================================================

* Copyright 2023 Manzia AI Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Material Kit 2 React examples
// import HorizontalTeamCard from "examples/Cards/TeamCards/HorizontalTeamCard";

// Manzia
import WorkCard from "pages/HomePage/components/WorkCard";

function Onboarding() {
  return (
    <MKBox
      component="section"
      variant="gradient"
      bgColor="dark"
      position="relative"
      py={6}
      px={{ xs: 2, lg: 0 }}
      mx={-2}
    >
      <Container>
        <Grid container>
          <Grid item xs={12} sx={{ mb: 6 }}>
            <MKTypography variant="h3" color="white">
              Just 30 minutes to onboard...
            </MKTypography>
            <MKTypography variant="body1" color="white" opacity={0.8}>
              Signup and onboard in less than 30 minutes. No credit card required.
            </MKTypography>
          </Grid>
        </Grid>
        <Grid container spacing={3}>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "1", 
                  color: "info", 
                  label: "Sign up", 
                  description: "Sign up and enter basic business information." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={1}>
              <WorkCard
                step={{ 
                  name: "2", 
                  color: "info", 
                  label: "Products", 
                  description: "Add product name and URLs from which to extract product details." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "3", 
                  color: "info", 
                  label: "Customize", 
                  description: "Customize ManziaBot using test conversations and mark examples." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "4", 
                  color: "info", 
                  label: "Styling", 
                  description: "Style ManziaBot to match your brand image." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "5", 
                  color: "info", 
                  label: "Deploy", 
                  description: "Deploy to your website using Google Tag Manager." 
                }}
              />
            </MKBox>
          </Grid>
          <Grid item xs={12} sm={6} md={4}>
            <MKBox mb={{ xs: 1, lg: 0 }}>
            <WorkCard
                step={{ 
                  name: "6", 
                  color: "info", 
                  label: "Add Funds", 
                  description: "Add funds to begin engaging website visitors." 
                }}
              />
            </MKBox>
          </Grid>
        </Grid>
      </Container>
    </MKBox>
  );
}

export default Onboarding;
