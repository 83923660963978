/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by www.manzia.com

 =========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

import { useState } from "react";

import PropTypes from "prop-types";

// @mui material components
import IconButton from "@mui/material/IconButton";
import Icon from "@mui/material/Icon";
// import Grid from "@mui/material/Grid"

// Material Dashboard 2 PRO React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";

// Copy
import copy from "clipboard-copy";

// Material Dashboard 2 PRO React Base Styles
import colors from "assets/theme/base/colors";
import typography from "assets/theme/base/typography";

// Manzia
import { ManziaDemo } from "appConstants";

// Constants
const { grey } = colors;
const { size } = typography;

function ShareLink({ product }) {

  const [copied, setCopied] = useState(false);

  const link = new URL(product.productPage);
  const linkDomain = link.hostname.replace("www.", "").replaceAll("-", "_").replaceAll(".", "-");
  const shareLink = `https://${ManziaDemo.shortDomain}/u/${linkDomain}`;

  const copyClipboard = () => {
    const copyText = document.getElementById("manzia-demo-link");
    copy(copyText.innerText);
    setCopied(true);
  }

    return (
      <MKBox mb={2} p={1} sx={{ backgroundColor: grey[100] }}>
          <MKBox display="flex" justifyContent="space-between" alignItems="center">
            <MKTypography variant="body2" color="dark" fontWeight="bold">
              Share demo link:
            </MKTypography>
            <MKBox display="flex" alignItems="center">
              <IconButton 
                  size="small" 
                  aria-label="copy share link" 
                  color="inherit" 
                  onClick={copyClipboard}
              >
                <Icon fontSize="small">content_copy</Icon>
              </IconButton>
              <MKTypography variant="caption" color="success">
                {copied ? "Copied" : ""}
              </MKTypography>
            </MKBox>
          </MKBox>
          <MKTypography 
            id="manzia-demo-link"
            color="info" 
            sx={{ fontSize: size.md, overflowWrap: "break-word" }}
            >
              {shareLink}
          </MKTypography>
      </MKBox>
    );
}

ShareLink.propTypes = {
    product: PropTypes.shape({
      productPage: PropTypes.string.isRequired,
      productName: PropTypes.string
    })
  };

export default ShareLink;
