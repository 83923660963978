/**

* Copyright 2023 Manzia Inc (https://www.manzia.com)

Coded by Manzia Inc

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.
*/

// React router
import { useNavigate } from "react-router-dom";

// @mui material components
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import Card from "@mui/material/Card";

// Material Kit 2 React components
import MKBox from "components/MKBox";
import MKTypography from "components/MKTypography";
import MKButton from "components/MKButton";

// About Us page sections
import Newsletter from "pages/HomePage/sections/Newsletter";
import TargetCustomer from "pages/HomePage/sections/TargetCustomer";
import Differentiate from "pages/HomePage/sections/Differentiate";
import Pricing from "pages/HomePage/sections/Pricing";
import Working from "pages/HomePage/sections/Working";
import Features from "pages/HomePage/sections/Features";
import Onboarding from "pages/HomePage/sections/Onboarding";
import Featuring from "pages/HomePage/sections/Featuring";
import Customize from "pages/HomePage/sections/Customize";

// Images
import bgImage from "assets/images/bg-about-us.jpg";
// import bgImage from "assets/images/manzia-background.png"

function getXS(width) {
  if (width < 300) return "120vh";
  if (width < 400) return "95vh";
  if (width > 500) return "80vh";
  return "70vh";
}

function HomePage() {
  const navigate = useNavigate();

  const gotoDemo = () => navigate("/demo");
  const windowWidth = window.innerWidth;
  const windowHeight = window.innerHeight;

  return (
    <>
      <MKBox
        minHeight={{ 
          xs: getXS(windowWidth), 
          sm: "70vh", 
          md: windowWidth > 900 ? "50vh" : "65vh", 
          lg: windowHeight < 1000 ? "90vh" : "50vh", 
          xl: "90vh", 
          xxl: "80vh" 
        }}
        width="100%"
        sx={{
          backgroundImage: ({ functions: { linearGradient, rgba }, palette: { gradients } }) =>
            `${linearGradient(
              rgba(gradients.dark.main, 0.6),
              rgba(gradients.dark.state, 0.6)
            )}, url(${bgImage})`,
          backgroundSize: "cover",
          backgroundPosition: "center",
          display: "grid",
          placeItems: "center",
        }}
      >
        <Container>
          <Grid
            container
            item
            xs={12}
            lg={10}
            justifyContent="center"
            alignItems="center"
            flexDirection="column"
            sx={{ mx: "auto", textAlign: "center" }}
          >
            <Grid item xs={12}>
              <MKTypography
                variant="h1"
                color="white"
                sx={({ breakpoints, typography: { size } }) => ({
                  [breakpoints.down("md")]: {
                    fontSize: size["3xl"],
                  },
                  marginTop: 6
                })}
              >
                Engage and qualify exiting website visitors
              </MKTypography>
            </Grid>
            <Grid item xs={12}>
              <MKBox my={2}>
                {/* <MKTypography variant="body1" color="white" opacity={1.0} mt={1} mb={3} fontWeight="regular">
                  Use ManziaBot to find out the real reasons your website visitors don&apos;t convert and turn them into valuable leads through engaging conversations.
                </MKTypography> */}
                <MKTypography variant="body1" color="white" opacity={1.0} mt={1} mb={3} fontWeight="regular">
                  Dissatisfied with the quantity and quality of leads from your website? Use ManziaBot to engage exiting web visitors and drive qualified leads into your sales pipeline.
                </MKTypography>
              </MKBox>
              
              <MKButton 
                color="default" 
                sx={{ color: ({ palette: { dark } }) => dark.main }}
                size="large"
                onClick={gotoDemo}
              >
                Try live demo
              </MKButton>
              {/* <MKTypography variant="h6" color="white" mt={1} mb={1}>
                No credit card required.
              </MKTypography> */}
            </Grid>
          </Grid>
        </Container>
      </MKBox>
      <Card
        sx={{
          p: 2,
          mx: { xs: 2, lg: 3 },
          mt: { xs: -12, md: -10, lg: -10 },
          mb: 4,
          boxShadow: ({ boxShadows: { xxl } }) => xxl,
        }}
      >
        <TargetCustomer />
        <Differentiate />
        <Pricing />
        <Working />
        <Featuring />
        <Customize />
        <Features />
        <Onboarding />
        <Newsletter />
      </Card>
    </>
  );
}

export default HomePage;
